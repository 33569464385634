<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="calculator" />
				<span>财务</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 账单管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="6">
						<a-form-item :label="`发生时间：`">
							<a-range-picker  :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" :placeholder="['开始时间', '结束时间']" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getPageList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">账单列表</h3>
					</div>
					<div class="right">
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}" :data-source="list" :scroll="{ x: 1200 }" rowKey="acc_id" size="small">
				 	<span slot="doing" slot-scope="text, data">
						<span style="color: #1890ff;cursor: pointer;" @click="doAccount(data)">确认处理</span>
					</span>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [
		{
			title: "存币总金额(元)",
			dataIndex: "in_amount",
		},
		{
			title: "取币总金额(元)",
			dataIndex: "out_amount",
		},
		{
			title: "微信入账(元)",
			dataIndex: "wx_inamount",
		},
		{
			title: "支付宝入账(元)",
			dataIndex: "ali_inamount",
		},
		{
			title: "微信出账(元)",
			dataIndex: "wx_outamount",
		},
		{
			title: "支付宝出账(元)",
			dataIndex: "ali_outamount",
		},
		{
			title: "人工出账(元)",
			dataIndex: "man_outamount",
		},
		{
			title: "微信汇款(元)",
			dataIndex: "wx_amount",
		},
		{
			title: "支付宝汇款(元)",
			dataIndex: "ali_amount",
		},
		{
			title: "生成时间",
			dataIndex: "add_time",
			width:160
		},
		{
			title: "操作",
			width: 80,
			dataIndex: "doing",
			scopedSlots: {
				customRender: 'doing'
			},
		},
	];

	export default {
		data() {
			return {
				expand: false,
				columns,
				searchData: {
					page: 1,
					start_date: '',
					end_date: ''
				},
				list: [],
				total_page: 0,
				selectedRowKeys: [],
				selectedRowDataList: [],
				editIndex: 0,
				detailLoading: false,
				detailList: [],
				pickerKey: Math.random()
			};
		},
		mounted() {
			this.getPageList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			},
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					start_date: '',
					end_date: ''
				}
				this.getPageList();
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getPageList();
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			// 列表
			getPageList() {
				http({
					method: 'post',
					url: '/api.account.list/',
					data: {
						...this.searchData,
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			doAccount(data){
				this.$confirm({
					title: '是否确定处理该账单?',
					okText: '确定',
					okType: 'primary',
					cancelText: '取消',
					onOk : () => {
						http({
							method: 'post',
							url: '/api.account.confirm/',
							data: {
								acc_id: data.acc_id,
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('账单确定处理完成');
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.imgs {
		display: flex;
	}

	.imgs img {
		width: 140px;
		height: 200px;
	}
</style>
